import Player from "@vimeo/player";
import React, { useEffect, useRef, useState } from "react";
import VideoPlayIcon from './VideoPlayIcon';
import { FaExpand } from "react-icons/fa";

const ConceptVideo = ({ videoId }) => {
    const playerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false); // Track the play/pause state
    const [showControls, setShowControls] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const controlsTimeout = useRef(null);
    const ref = useRef(null);

    const handleFullscreen = () => {
        if (ref.current) {
            if (!document.fullscreenElement) {
                ref.current.requestFullscreen();
                setIsFullscreen(true);
            } else {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };

    useEffect(() => {
        let player;
        setIsPlaying(false);
        if (playerRef.current) {
            // Initialize the player only once
            player = new Player(playerRef.current, {
                url: videoId,
                responsive: true,
                controls: true, // Show controls only in fullscreen mode
            });

            // Update play state when the video starts or pauses
            player.on("play", () => setIsPlaying(true));
            player.on("pause", () => setIsPlaying(false));

            // Listen for fullscreen changes
            const onFullscreenChange = () => {
                setIsFullscreen(!!document.fullscreenElement);
            };
            document.addEventListener("fullscreenchange", onFullscreenChange);

            return () => {
                player.destroy(); // Cleanup player on component unmount
                document.removeEventListener("fullscreenchange", onFullscreenChange);
            };
        }
    }, [videoId]);

    // Pause/Play when user clicks custom button
    const handlePlayPause = () => {
        if (playerRef.current) {
            const player = new Player(playerRef.current); // Reuse player instance
            if (isPlaying) {
                player.pause();
                player.setVolume(1);
            } else {
                player.play();
                player.setVolume(1);
            }
        }
        setIsPlaying(!isPlaying);
    };

    // Handle mouse movement to show/hide controls
    const handleMouseMove = () => {
        setShowControls(true);
        if (controlsTimeout.current) {
            clearTimeout(controlsTimeout.current);
        }

        controlsTimeout.current = setTimeout(() => {
            setShowControls(false);
        }, 2000);
    };

    // Pause the video when it is fully scrolled out of view
    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const isFullyVisible = rect.bottom > 0 && rect.top < window.innerHeight;

                if (!isFullyVisible && isPlaying) {
                    const player = new Player(playerRef.current);
                    player.pause();
                    handlePlayPause();
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isPlaying]);

    useEffect(() => {
        return () => {
            if (controlsTimeout.current) {
                clearTimeout(controlsTimeout.current);
            }
        };
    }, []);

    return (
        <div
            className="relative w-full max-h-[88vh] h-auto aspect-video flex items-center justify-center overflow-hidden"
            ref={ref}
            onMouseMove={handleMouseMove}
        >
            <div
                ref={playerRef}
                className="absolute top-0 left-0 w-full h-full"
                style={{
                    objectFit: "cover",
                }}
            ></div>

            {/* Show controls if playing or fullscreen */}
            {((showControls || !isPlaying) && !isFullscreen) ? (
                <button
                    type="button"
                    className="absolute top-2/4 -translate-y-2/4"
                    id="playVideo"
                    title="Click to play video"
                    onClick={handlePlayPause}
                >
                    <VideoPlayIcon isPlaying={isPlaying} />
                    <span className="hidden">Play Video</span>
                </button>
            ) : (
                <>
                    {!isFullscreen ? <div className="absolute top-[40%] w-full h-auto aspect-video max-h-screen"></div> : ""}
                </>
            )}

            {/* Fullscreen Button */}
            {!isFullscreen && (
                <button
                    onClick={handleFullscreen}
                    className="absolute bottom-4 right-4 text-white p-2"
                >
                    <FaExpand color="text-white" className="size-6 md:size-8 lg:size-8 2xl:size-10" />
                </button>
            )}
        </div>
    );
};

export default ConceptVideo;
