import React from "react";
import Layout from "../shared/Layout";
import HeroVideo from "../components/HeroVideo";
import MainBanner from "../components/MainBanner";
import DiscoverEcosystem from "../components/DiscoverEcosystem";
import ConceptVideo from "../components/ConceptVideo";
import BecomePart from "../components/BecomePart";
import EmpowerAndProsper from "../components/EmpowerAndProsper";
import MoreThenPlatform from "../components/MoreThenPlatform";
import JoinRevolution from "../components/JoinRevolution";
import HowDoesWork from "../components/HowDoesWork";
import WhySocieti from "../components/WhySocieti";
import WhatExpertsSay from "../components/WhatExpertsSay";
import LaunchDigitalLegacy from "../components/LaunchDigitalLegacy";
import SocietiRoadmap from "../components/SocietiRoadmap";
import StayConnected from "../components/StayConnected";

const MainLanding = () => {
  return (
    <Layout
      title="Societi"
      description="Societi - The World's First Digital Society That Is Owned And Controlled By Its Members."
      bannerUrl="/img/mainbanner.jpg"
      pageUrl=""
    >
      <HeroVideo videoId="1024914425" isAutoPlay={true} />
      <MainBanner />
      <DiscoverEcosystem />
      <ConceptVideo isAutoPlay={false} videoId="1024916389" />
      <BecomePart />
      <EmpowerAndProsper />
      <MoreThenPlatform />
      <HowDoesWork />
      <JoinRevolution />
      <WhySocieti />
      <WhatExpertsSay />
      <LaunchDigitalLegacy />
      <SocietiRoadmap />
      <StayConnected />
    </Layout>
  );
};

export default MainLanding;
