import React from "react";
import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom";

import CryptoatiLogo from "../assets/images/CryptoatiLogo.png";
import facebook from "../assets/images/facebook.png";
import MainBG from "../assets/images/faqBg.png";
import linkedIn from "../assets/images/linkedIn.png";
import twitter from "../assets/images/twitter.png";
import youtube from "../assets/images/Youtube.png";
import Faq from "../components/Faq";
import IconContainer from "../components/IconContainer";
import { BASE_URL, MAIL_TO_URL } from "../Constants";
import LazyImage from "../widgets/LazyImage";


const FooterWithFaq = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="relative overflow-hidden">
        <div className="absolute bottom left-2/4 -translate-x-2/4 h-full w-[110vw] blur-[250px] bg-[linear-gradient(180deg,_rgba(51,39,194,0.61)_28.93%,_rgba(121,254,251,0.61)_100%)] pointer-events-none" />
        <LazyImage
          src={MainBG}
          alt="Footer background shape"
          title="Footer background shape"
          className="object-cover absolute inset-0 h-full w-full pointer-events-none opacity-35 md:opacity-100"
          loading="lazy"
        />

        <Faq />

        <footer className={`relative pt-16`}>
          <div className=" absolute inset-0 backdrop-blur-[2px] bg-[linear-gradient(0deg,_rgba(16,2,39,0)_0.01%,_rgba(16,2,39,0.78)_100%)] pointer-events-none" />
          <div className="absolute bottom-0 left-0 h-[50px] w-[30%] bg-primary blur-[60px] pointer-events-none " />
          <div className="absolute bottom-0 right-0 h-[50px] w-[30%] bg-pink blur-[80px] pointer-events-none " />

          <div className="container relative z-[1]">
            <div className="grid grid-cols-12 gap-y-10 gap-x-6">
              <div className="col-span-12 md:col-span-4 text-center sm:text-left">
                <Link
                  to={BASE_URL}
                  className="inline-flex mb-6 md:mb-10 2xl:mb-12"
                >
                  <LazyImage
                    src={CryptoatiLogo}
                    alt="Societi"
                    title="Societi"
                    className="h-[40px] md:h-[50px]"
                    loading="lazy"
                  />
                  <span className="sr-only">Societi</span>
                </Link>
                <p className=" text-white text-lg 2xl:text-xl">
                  "Explore exclusive Digital Key's and elevate your digital ownership
                  experience with Societi."
                </p>
              </div>
              <div className="col-span-12 sm:col-span-6 md:col-span-4 md:px-6 lg:px-20 md:flex md:justify-end sm:border-r sm:border-white/30">
                <div className="text-center sm:text-left">
                  <h4 className="text-xl 2xl:text-2xl text-white font-bold  mb-5 2xl:mb-7">
                    Quick Links
                  </h4>
                  <div className="flex flex-col gap-3 2xl:gap-4">
                    <div className="relative">
                      <Link
                        to={BASE_URL}
                        className="inline-flex w-fit items-center gap-4 text-white text-base md:text-lg 2xl:text-xl hover:underline"
                      >
                        Terms of Service
                      </Link>
                    </div>
                    <div className="relative">
                      <Link
                        to={BASE_URL}
                        className="inline-flex w-fit items-center gap-4 text-white text-base md:text-lg 2xl:text-xl hover:underline"
                      >
                        Privacy Policy
                      </Link>
                    </div>
                    <div className="relative">
                      <Link
                        to={BASE_URL}
                        className="inline-flex w-fit items-center gap-4 text-white text-base md:text-lg 2xl:text-xl hover:underline"
                      >
                        Help & Support
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 md:col-span-4 sm:px-6 lg:px-0 md:flex md:justify-center">
                <div className="text-center sm:text-left">
                  <h4 className="text-xl 2xl:text-2xl text-white font-bold  mb-5 2xl:mb-7">
                    Contact Us
                  </h4>
                  <Link
                    to={MAIL_TO_URL}
                    className="inline-flex items-center gap-2.5 2xl:gap-4 text-white text-base md:text-lg 2xl:text-xl hover:underline"
                  >
                    <IoIosMail className="text-2xl 2xl:text-3xl" />{" "}
                    info@societi.com
                  </Link>
                  <div className="flex  justify-between mt-5">

                    <a href="#" target="_blank" className="cursor-pointer" aria-label="Linkedin" rel="noopener">
                      <IconContainer iconName={linkedIn} alt="Linkedin" />

                      <span className="hidden" >Linkedin</span>
                    </a>
                    <a href="https://x.com/Societiio" target="_blank" className="cursor-pointer" aria-label="twitter" rel="noopener">
                      <IconContainer iconName={twitter} alt="X" />

                      <span className="hidden" >X</span>
                    </a>
                    <a href="#" target="_blank" className="cursor-pointer" aria-label="Facebook" rel="noopener">
                      <IconContainer iconName={facebook} alt="Facebook" />

                      <span className="hidden" >Facebook</span>
                    </a>
                    <a href="https://www.youtube.com/channel/UCyVywIUfLzn0CXliWqHrp2A" target="_blank" className="cursor-pointer" aria-label="youtube" rel="noopener">
                      <IconContainer iconName={youtube} alt="Youtube" />
                      <span className="hidden" >Youtube</span>
                    </a>

                  </div>
                </div>
              </div>
            </div>
            <div className="relative border-t border-white/30 py-6 mt-10 text-lg text-white">
              <p className="text-base md:text-lg text-center sm:text-left ">
                © {currentYear} Societi. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default FooterWithFaq;
