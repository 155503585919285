import Player from "@vimeo/player";
import React, { useEffect, useRef, useState } from "react";

const SmallVideo = ({ videoId, currentlyPlayingId, setCurrentlyPlayingId }) => {
    const playerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showControls, setShowControls] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const controlsTimeout = useRef(null);
    const containerRef = useRef(null);


    useEffect(() => {
        let player;
        if (playerRef.current) {
            player = new Player(playerRef.current, {
                url: videoId,
                responsive: true,
                controls: true, // Custom controls
            });

            player.on("play", () => {
                setIsPlaying(true);
                setCurrentlyPlayingId(videoId);
            });

            player.on("pause", () => setIsPlaying(false));

            return () => {
                player.destroy();
            };
        }
    }, [videoId, setCurrentlyPlayingId]);

    // Pause if another video starts playing
    useEffect(() => {
        if (currentlyPlayingId !== videoId && isPlaying) {
            const player = new Player(playerRef.current);
            player.pause();
            setIsPlaying(false);
        }
    }, [currentlyPlayingId, videoId, isPlaying]);

    // Pause the video if it scrolls out of view
    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                const isVisible = rect.bottom > 0 && rect.top < window.innerHeight;

                if (!isVisible && isPlaying) {
                    const player = new Player(playerRef.current);
                    player.pause();
                    setIsPlaying(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isPlaying]);

    const handlePlayPause = () => {
        if (playerRef.current) {
            const player = new Player(playerRef.current);
            if (isPlaying) {
                player.pause();
            } else {
                player.play();
                setCurrentlyPlayingId(videoId);
            }
        }
        setIsPlaying(!isPlaying);
    };

    const handleMouseMove = () => {
        setShowControls(true);
        if (controlsTimeout.current) {
            clearTimeout(controlsTimeout.current);
        }

        controlsTimeout.current = setTimeout(() => {
            setShowControls(false);
        }, 2000);
    };

    useEffect(() => {
        return () => {
            if (controlsTimeout.current) {
                clearTimeout(controlsTimeout.current);
            }
        };
    }, []);

    return (
        <div
            className="relative w-full h-auto aspect-video flex items-center justify-center overflow-hidden border border-purple rounded-[20px] bg-dark p-4"
            ref={containerRef}
            onMouseMove={handleMouseMove}
        >
            <div
                ref={playerRef}
                className="absolute top-0 left-0 w-full h-full"
                style={{
                    objectFit: "cover",
                }}
            ></div>

            {/* {((showControls || !isPlaying) && !isFullscreen) && (
                <button
                    type="button"
                    className="absolute top-2/4 -translate-y-2/4"
                    onClick={handlePlayPause}
                >
                    <VideoPlayIcon isPlaying={isPlaying} />
                </button>
            )} */}

        </div>
    );
};

export default SmallVideo;
