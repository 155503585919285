import React, { useEffect, useState } from "react";
import { CgClose, CgMenuRightAlt } from "react-icons/cg";
import { Link } from "react-router-dom";
import CryptoatiLogo from "../assets/images/CryptoatiLogo.png";
import {
  BASE_URL,
  COMMUNITY_APP_URL,
  NFT_SALE_URL,
  WHITEPAPER_URL
} from "../Constants";
import LazyImage from "../widgets/LazyImage";

const Header = () => {
  const [showScroll, setShowScroll] = useState(false);
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    });
  }, []);

  const headLinks = [
    {
      url: WHITEPAPER_URL,
      title: "Whitepaper",
      target: "_blank",
    },
    {
      url: NFT_SALE_URL,
      title: "Buy Digital Key",
    },
    {
      url: COMMUNITY_APP_URL,
      title: "Get Started",
    },
  ];

  const redirectHandler = (url, target = "") => {
    if (target === "_blank") {
      window.open(url, '_blank');

    }
    else {
      window.location.href = url
    }

  }

  return (
    <>
      <header
        className={`fixed top-0 left-0 w-full h-16 md:h-20 2xl:h-24 flex items-center z-50 ${showScroll || menu ? "bg-dark" : ""
          } transition-all `}
      >
        <div className="container">
          <div className="flex items-center justify-between gap-5">
            <Link to={BASE_URL} className="inline-flex gap-1">
              <LazyImage
                src={CryptoatiLogo}
                className="h-[40px] md:h-[50px]"
                alt="Societi logo"
                title="Societi"
              />
              {/* <LazyImage
                src={LogoSecond}
                className="h-5 sm:h-6 md:h-[30px] hidden md:inline-flex"
                alt="Societi logo"
                title="Societi"
              /> */}
              <span className="sr-only">Societi</span>
            </Link>

            <div className="flex items-center justify-between gap-5">
              <button
                type="button"
                className="text-2xl text-white md:hidden "
                onClick={() => setMenu(!menu)}
              >
                {menu ? <CgClose className="text-pink" /> : <CgMenuRightAlt />}
                <span className="sr-only">Menu</span>
              </button>
              <div
                className={`nav absolute md:relative top-full md:top-auto ${menu ? "left-0" : "left-full"
                  } md:left-auto w-full md:w-auto bg-dark md:bg-transparent transition-all`}
              >
                <ul className="flex flex-col md:flex-row items-center md:gap-6 lg:gap-10">
                  {headLinks.map((items, i) => (
                    <li
                      key={i}
                      className="font-orbitron p-3 md:p-0 text-center w-full md:w-auto border-t border-white/30 last:border-b md:!border-0 cursor-pointer"
                    >
                      <span

                        onClick={() => {
                          redirectHandler(items.url, items.target)
                        }}
                        className="font-orbitron font-bold text-lg lg:text-xl 2xl:text-2xl  text-white hover:text-pink transition-all"
                      >
                        {items.title}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
