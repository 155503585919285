import React, { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";
import { useInView } from 'react-intersection-observer';
import VideoPlayIcon from './VideoPlayIcon';

const VimeoPlayer = ({ videoId }) => {
    const playerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false); // Track the play/pause state
    const [showControls, setShowControls] = useState(true);
    const controlsTimeout = useRef(null);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5, // Load when 50% of the component is visible
    });


    useEffect(() => {
        let player;
        if (playerRef.current) {
            player = new Player(playerRef.current, {
                id: videoId,
                responsive: true, // Ensures the player adjusts to container dimensions
                autoplay: true,
                muted: true
            });

            // Update play state when the video starts or pauses
            player.on("play", () => setIsPlaying(true));
            player.on("pause", () => setIsPlaying(false));

            return () => {
                player.destroy(); // Cleanup player on component unmount
            };
        }
    }, [videoId]);


    const handlePause = () => {
        debugger
        if (playerRef.current) {
            const player = new Player(playerRef.current);
            if (isPlaying) {
                player.pause();
                player.setVolume(1);

            } else {
                player.play();
                player.setVolume(1);

            }
        }
        setIsPlaying(!isPlaying);

    };

    const handlePlay = () => {
        if (playerRef.current) {
            const player = new Player(playerRef.current);
            if (isPlaying) {
                player.pause();
            } else {
                player.play();
            }
        }
        setIsPlaying(!isPlaying);
    };




    useEffect(() => {
        const handleScroll = () => {
            const player = playerRef.current;
            if (player) {
                const boundingRect = player.getBoundingClientRect();
                const isCompletelyOutOfView = boundingRect.bottom < 0 || boundingRect.top > window.innerHeight;
                if (isCompletelyOutOfView && isPlaying) {
                    handlePause();
                } else if (!isCompletelyOutOfView && !isPlaying) {
                    // Optionally, auto-play when back in view
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isPlaying]);

    const handleMouseMove = () => {
        setShowControls(true);

        if (controlsTimeout.current) {
            clearTimeout(controlsTimeout.current);
        }

        controlsTimeout.current = setTimeout(() => {
            setShowControls(false);
        }, 2000);
    };

    useEffect(() => {
        return () => {
            if (controlsTimeout.current) {
                clearTimeout(controlsTimeout.current);
            }
        };
    }, []);

    return (
        <div className="relative w-full h-auto max-h-screen aspect-video flex items-center justify-center player-wrapper" ref={ref}
            onMouseMove={handleMouseMove}>

            <div
                ref={playerRef}
                className="absolute top-0 left-0 w-full h-full"
                style={{
                    objectFit: "cover", // Ensures the video fits properly
                    height: "100%"
                }}
            ></div>


        </div >
    );
};

export default VimeoPlayer;
