import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

import LeftShape from "../assets/images/ourjorny-left-shape.png";
import RightShape from "../assets/images/ourjorny-right-shape.png";
import BlogCard from "./Cards/BlogCard";

import TwitterBlogImage from "../assets/images/twitter-blog-img.png";
import YoutubeBlogImage from "../assets/images/youtube-blog-img.png";
import ComingSoonBlogImage from "../assets/images/comming-soon-image.png";
import LazyImage from "../widgets/LazyImage";
import SmallVideo from "./video";

const StayConnected = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

  // const cardData = [
  //   {
  //     image: TwitterBlogImage,
  //     title: "How we make our videos",
  //     description:
  //       "Unlock a cascade of rewards as you climb the SOCIETI Digital Keys.",
  //   },
  //   {
  //     image: YoutubeBlogImage,
  //     title: "How we make our videos",
  //     description:
  //       "Unlock a cascade of rewards as you climb the SOCIETI Digital Keys.",
  //   },
  //   {
  //     image: ComingSoonBlogImage,
  //     title: "Societi",
  //     description: "Launching the community platform soon.",
  //   },
  //   {
  //     image: TwitterBlogImage,
  //     title: "How we make our videos",
  //     description:
  //       "Unlock a cascade of rewards as you climb the SOCIETI Digital Keys.",
  //   },
  //   {
  //     image: YoutubeBlogImage,
  //     title: "How we make our videos",
  //     description:
  //       "Unlock a cascade of rewards as you climb the SOCIETI Digital Keys.",
  //   },
  //   {
  //     image: ComingSoonBlogImage,
  //     title: "Societi",
  //     description: "Launching the community platform soon.",
  //   },
  // ];
  const cardData = ["1024914425", "1024916389"]
  return (
    <div className="relative py-20 md:py-28 2xl:py-32 bg-sec">
      <LazyImage
        src={LeftShape}
        className="absolute left-0 -top-14 md:-top-20 2xl:-top-28 w-[80px] md:w-[160px] 2xl:w-[205px] pointer-events-none"
        alt="Left side background shape"
        title="Left side background shape"
        loading="lazy"
      />
      <LazyImage
        src={RightShape}
        className="absolute top-2/4 -translate-y-2/4 right-0 h-[calc(100%-30px)] max-w-[80%] object-scale-down object-right pointer-events-none"
        alt="Right side background shape"
        title="Right side background shape"
        loading="lazy"
      />
      <div className="container px-6 relative z-[1]">
        <div className="relative text-center mb-12 md:mb-20 2xl:mb-24">
          <h2 className="text-2xl md:text-4xl 2xl:text-5xl !leading-tight mb-4 md:mb-6 2xl:mb-10 font-black uppercase text-white max-w-3xl mx-auto">
            Stay Connected with Societi
          </h2>
          <p className="text-white font-medium text-base md:text-xl 2xl:text-2xl">
            Follow us on our social platforms to get the latest updates, join
            community discussions, and be part of our growing movement towards a
            decentralized future. Let’s build this digital society together!
          </p>
        </div>
        <div className="relative flex items-center max-w-sm md:max-w-full mx-auto ">
          <div className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 h-full w-full block rounded-full bg-lrcircle blur-[80px] pointer-events-none"></div>
          <button
            className="SliderArrow -left-[20px] lg:-left-[24px] top-2/4 -translate-y-2/4 z-[2]"
            ref={navigationPrevRef}
          >
            <FiArrowLeft />
            <span className="sr-only">Previous</span>
          </button>
          <Swiper
            spaceBetween={30}
            breakpoints={{
              1024: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              320: {
                slidesPerView: 1,
              },
            }}
            modules={[Navigation]}
            pagination={{
              clickable: true,
            }}
            className="w-full"
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                if (swiper.params) {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;

                  swiper.navigation.destroy();
                  swiper.navigation.init();
                  swiper.navigation.update();
                }
              });
            }}
          >
            {cardData.map((item, i) => (
              <SwiperSlide className="!h-[initial]" key={i}>
                {/* <BlogCard
                  image={items.image}
                  title={items.title}
                  description={items.description}
                /> */}
                <SmallVideo videoId={item} key={item} currentlyPlayingId={currentlyPlayingId} setCurrentlyPlayingId={setCurrentlyPlayingId} />

              </SwiperSlide>
            ))}
          </Swiper>
          <button
            className="SliderArrow -right-[20px] lg:-right-[24px] top-2/4 -translate-y-2/4 z-[2]"
            ref={navigationNextRef}
          >
            <FiArrowRight />
            <span className="sr-only">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StayConnected;
